<template>
  <div class="create-promo" v-if="getPromo && Object.keys(getPromo).length">
    <HeaderPromo
      class="mb-7"
      :class="{ 'mt-4': !$vuetify.breakpoint.lgAndUp }"
      :caption="'Создание/редактирование промотовара'"
    />
    <SearchProduct class="mt-7 mb-4" @add="searchPromoInfo" />

    <v-form v-model="valid" @submit.prevent="validate" ref="card">
      <div class="grey create-promo__top lighten-4 pa-5 mb-7">
        <div class="white px-4 py-5">
          <v-row class="my-0">
            <v-col cols="12" md="3" class="py-0">
              <div class="create-promo__image-container">
                <img
                  class="create-promo__image"
                  v-if="getPromo.image"
                  :src="getPromo.image"
                />
                <div
                  class="create-promo__image create-promo__image_default"
                  v-else
                >
                  <v-icon color="#fff" x-large>
                    mdi-image-multiple-outline
                  </v-icon>
                </div>
              </div>
              <div
                class="create-promo__image-upload mt-1"
                v-if="$vuetify.breakpoint.lgAndUp"
              >
                <input
                  class="create-promo__image-input"
                  @change="uploadImage"
                  type="file"
                  name="image"
                  accept="image/png, image/jpeg"
                />
                Загрузить <br />изображение
              </div>
              <v-btn
                v-else
                outlined
                color="#0A467E"
                tile
                elevation="0"
                :exact="true"
                text
                block
                class="my-8"
                height="44"
              >
                <input
                  class="create-promo__image-input"
                  @change="uploadImage"
                  type="file"
                  name="image"
                  accept="image/png, image/jpeg"
                />
                Загрузить изображение
              </v-btn>
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <div class="create-promo__text-block mb-2">
                <div class="create-promo__title mb-4">Название</div>
                <v-textarea
                  v-for="lang in supportedLanguages"
                  :key="lang.id"
                  v-model="name[lang.code]"
                  outlined
                  rows="1"
                  auto-grow
                  :rules="[Rules.maxLength(80), Rules.notEmpty]"
                  :label="lang.code.toUpperCase()"
                  background-color="#fff"
                  color="#858585"
                  counter="80"
                  class="v-input--counted"
                >
                </v-textarea>
              </div>
              <div class="create-promo__text-block">
                <v-text-field
                  v-model="article"
                  outlined
                  rows="1"
                  auto-grow
                  label="Артикул"
                  :rules="[Rules.notEmpty]"
                  background-color="#fff"
                  color="#858585"
                  class="v-input--counted"
                >
                </v-text-field>
              </div>
              <div class="create-promo__text-block">
                <v-text-field
                  v-model="local_sku"
                  outlined
                  rows="1"
                  auto-grow
                  label="Local SKU"
                  :rules="[Rules.notEmpty]"
                  background-color="#fff"
                  color="#858585"
                  class="v-input--counted"
                >
                </v-text-field>
              </div>
              <div class="create-promo__text-block">
                <v-text-field
                  v-for="lang in supportedLanguages"
                  :key="lang.id"
                  v-model="brandname[lang.code]"
                  outlined
                  rows="1"
                  auto-grow
                  :label="`Бренд ${lang.code.toUpperCase()}`"
                  :rules="[Rules.notEmpty]"
                  background-color="#fff"
                  color="#858585"
                  class="v-input--counted"
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="create-promo__text-block mb-7">
        <div class="create-promo__title mb-4">Краткие условия</div>
        <v-textarea
          v-for="lang in supportedLanguages"
          :key="lang.id"
          v-model="short_cond[lang.code]"
          outlined
          rows="1"
          auto-grow
          :label="lang.code.toUpperCase()"
          :rules="[Rules.maxLength(80), Rules.notEmpty]"
          background-color="#fff"
          color="#858585"
          class="v-input--counted"
          counter="80"
        >
        </v-textarea>
      </div>

      <div class="create-promo__text-block mb-10">
        <div class="create-promo__title mb-4">Полные условия</div>
        <div v-for="lang in supportedLanguages" :key="lang.id">
          <v-textarea
            v-model="promo_condition_text[lang.code]"
            outlined
            rows="1"
            auto-grow
            :label="lang.code.toUpperCase()"
            background-color="#fff"
            color="#858585"
            counter="1000"
            :rules="[Rules.maxLength(1000)]"
            class="v-input--counted"
          >
          </v-textarea>
          <div class="create-promo__file">
            <fileComponent
              @save="save(false)"
              :file="getFile(lang.id)"
              :lang="lang.id"
            />
          </div>
          <v-row class="mb-7" v-if="getFile(lang.id)">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="promo_condition_file_text[lang.code]"
                outlined
                :messages="lang.code.toUpperCase() + ' текст ссылки'"
                background-color="#fff"
                color="#858585"
                counter="25"
                :rules="[Rules.maxLength(25), Rules.notEmpty]"
                class="v-input--counted"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="create-promo__text-block mb-2">
        <div class="create-promo__title mb-4">Ссылка на акцию</div>
        <v-text-field
          v-for="lang in supportedLanguages"
          :key="lang.id"
          v-model="link[lang.code]"
          outlined
          :rules="[Rules.Link]"
          :messages="lang.code.toUpperCase() + ' ссылка'"
          background-color="#fff"
          color="#858585"
          class="mb-4"
          placeholder="Добавьте адрес страницы акции"
        >
        </v-text-field>
        <v-row>
          <v-col cols="12" md="4">
            <v-combobox
              v-for="lang in supportedLanguages"
              :key="lang.id"
              :items="buttonText[lang.code]"
              v-model="link_text[lang.code]"
              outlined
              :messages="lang.code.toUpperCase() + ' текст ссылки'"
              background-color="#fff"
              color="#858585"
              :rules="[Rules.maxLength(25)]"
              counter="25"
              placeholder="Выберите/введите"
              append-icon="mdi-chevron-down"
              class="mb-2 v-input--counted"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </div>
      <v-row class="mb-15">
        <v-col cols="12" md="4" class="js-picker">
          <div class="create-promo__text-block mb-7">
            <div class="create-promo__title mb-4">Даты показа</div>
            <v-text-field
              v-model="dateRangeText"
              label="Выберите дату"
              append-icon="mdi-calendar"
              readonly
              outlined
              hide-details="true"
              background-color="#FFFFFF"
              @focus="openCalendar = true"
            ></v-text-field>

            <div
              v-if="openCalendar"
              class="create-promo__calendar custom-header-color"
            >
              <v-date-picker
                v-model="dates"
                no-title
                range
                color="#008FC5"
                elevation="2"
                header-color="#008FC5"
                first-day-of-week="1"
                :min="dates[0]"
                :max="dates[1]"
              >
                <v-col>
                  <v-btn
                    elevation="0"
                    color="#0A467E"
                    width="100%"
                    tile
                    dark
                    :disabled="!!!dates.length"
                    @click.stop="openCalendar = false"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    class="mt-2"
                    color="#0A467E"
                    width="100%"
                    outlined
                    tile
                    @click.stop="dates = []"
                  >
                    Очистить
                  </v-btn>
                </v-col>
              </v-date-picker>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="create-promo__text-block mb-7">
            <div class="create-promo__title mb-4">Категория товара</div>
            <v-select
              v-model="category"
              :items="Categories"
              item-text="name"
              multiple
              chips
              :rules="[Rules.notEmpty]"
              item-value="id"
              placeholder="Выберите категорию"
              append-icon="mdi-chevron-down"
              outlined
              hide-details="true"
              background-color="#FFFFFF"
            ></v-select>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="create-promo__text-block mb-7">
            <div class="create-promo__title mb-4">Бейджи</div>
            <v-select
              v-model="badges[0]"
              :items="Badges"
              item-text="name"
              item-value="id"
              placeholder="Выберите бейдж"
              append-icon="mdi-chevron-down"
              clearable
              :disabled="disabledBadges"
              outlined
              hide-details="true"
              chips
              class="create-promo__badges"
              background-color="#FFFFFF"
            >
              <template #selection="{ item }">
                <v-chip
                  class="badge__chip"
                  :dark="!/^#([f|F]{6}|[f|F]{3}|[0]{8})$/.test(item.color)"
                  :color="item.color"
                  >{{ item.name }}</v-chip
                >
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters class="flex-nowrap" align="center">
                        <span class="badge__name">{{ item.name }}</span>
                        <v-spacer></v-spacer>
                        <div class="select__tools">
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              :color="hover ? '#0a467e' : ''"
                              class="mr-4"
                              @click.stop="editBadge(item)"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-hover>
                          <v-hover v-slot="{ hover }">
                            <v-icon
                              :color="hover ? '#D21242' : ''"
                              @click.stop="confirmDelete(item)"
                              >mdi-delete
                            </v-icon>
                          </v-hover>
                        </div>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #prepend-item>
                <div
                  class="create-promo__add-badge px-4 py-2 mt-n2"
                  @click="openBadgePopup()"
                >
                  <v-icon class="mr-2" style="color: currentColor">
                    mdi-plus
                  </v-icon>
                  Добавить
                </div>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>

      <div class="create-promo__text-block mb-7">
        <div class="create-promo__title mb-6">Промоцена и видимость</div>
        <v-row
          class="create-promo__table-head mb-4"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-col cols="2">
            <div class="create-promo__table-title">BN</div>
          </v-col>
          <v-col>
            <div class="create-promo__table-title">Старая цена</div>
          </v-col>
          <v-col>
            <div class="create-promo__table-title">Скидка</div>
          </v-col>
          <v-col>
            <div class="create-promo__table-title">Новая цена</div>
          </v-col>
        </v-row>
        <v-row align-md="center" v-for="i in userTypes" :key="i.id">
          <v-col cols="12" sm="2">
            <v-checkbox
              v-model="user_types"
              :ripple="false"
              :label="i.name"
              color="#008FC5"
              :rules="[Rules.checkboxReq]"
              :class="{ 'mt-0 mb-4': $vuetify.breakpoint.smAndUp }"
              :value="i.id"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              v-if="user_types.includes(i.id)"
              v-model="prices[i.code].old"
              outlined
              background-color="#fff"
              color="#858585"
              :suffix="Currency"
              :rules="[Rules.notEmpty]"
              :messages="$vuetify.breakpoint.smAndUp ? '' : 'Старая цена'"
              :class="{ 'v-input--counted': !$vuetify.breakpoint.smAndUp }"
              :hide-details="$vuetify.breakpoint.smAndUp"
              type="number"
              placeholder="0"
              hide-spin-buttons
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="user_types.includes(i.id)"
              v-model="prices[i.code].percent"
              outlined
              @change="discountChange()"
              background-color="#fff"
              color="#858585"
              suffix="%"
              type="number"
              :disabled="prices[i.code].disabled"
              placeholder="0"
              hide-spin-buttons
              :messages="$vuetify.breakpoint.smAndUp ? '' : 'Скидка'"
              :class="{ 'v-input--counted': !$vuetify.breakpoint.smAndUp }"
              :hide-details="$vuetify.breakpoint.smAndUp"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="user_types.includes(i.id)"
              v-model="prices[i.code].new"
              outlined
              background-color="#fff"
              color="#858585"
              :suffix="Currency"
              placeholder="0"
              :rules="[Rules.notEmpty]"
              :messages="$vuetify.breakpoint.smAndUp ? '' : 'Новая цена'"
              :class="{ 'v-input--counted': !$vuetify.breakpoint.smAndUp }"
              :hide-details="$vuetify.breakpoint.smAndUp"
              type="number"
              hide-spin-buttons
            >
            </v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <AddBadge
      v-if="addBadgeModal"
      @close="closeBadgePopup"
      :item="editedBadge"
    />
    <Dialog
      :title="'бейдж ' + deletedItem.name"
      v-if="deleteConfirm"
      @dialogState="removeBadge"
    />
  </div>
</template>

<script>
import HeaderPromo from "@/components/promo/HeaderPromo";
import fileComponent from "@/components/promo/fileComponent";
import SearchProduct from "@/components/promo/SearchProduct";
import Dialog from "@/components/help/Dialog.vue";
import AddBadge from "@/components/promo/addBadge";
import { mapActions, mapGetters } from "vuex";
import "@/assets/scss/promoDetail.scss";
export default {
  name: "PromoDetailProduct",
  components: {
    HeaderPromo,
    fileComponent,
    SearchProduct,
    AddBadge,
    Dialog,
  },
  data: (vm) => ({
    deleteConfirm: false,
    deletedItem: {},
    editedBadge: null,
    addBadgeModal: false,
    disabledBadges: false,
    openCalendar: false,
    dates: [],
    category: [],
    badges: [],
    user_types: [],
    name: {},
    short_cond: {},
    promo_condition_text: {},
    link: {},
    link_text: {},
    promo_condition_file_text: {},
    valid: null,
    prices: {},
    article: "",
    local_sku: "",
    brandname: {},
  }),
  computed: {
    ...mapGetters([
      "userTypes",
      "Categories",
      "Badges",
      "Rules",
      "getPromo",
      "getProducts",
    ]),
    dateRangeText() {
      return this.formatDate.join(" – ");
    },
    supportedLanguages() {
      if (
        this.getPromo.country &&
        this.getPromo.country.languages &&
        this.getPromo.country.languages.length
      ) {
        return this.getPromo.country.languages.map((el) => ({
          code: el.code,
          id: el.id,
        }));
      }
      return [
        { code: "ru", id: 1 },
        { code: "en", id: 2 },
      ];
    },
    formatDate() {
      return this.dates && this.dates.length
        ? [
            this.$moment(this.dates[0]).format("L"),
            this.$moment(this.dates[1]).format("L"),
          ]
        : [];
    },
    buttonText() {
      switch (this.$route.params.country) {
        case "ru":
          return {
            ru: ["В каталог"],
            en: ["В каталог"],
          };
        case "kz":
          return {
            ru: ["В каталог"],
            kk: ["Каталогқа"],
          };
        case "ua":
          return {
            ru: ["В каталог"],
            uk: ["В каталог"],
          };
      }
    },
    catalogHost() {
      switch (this.$route.params.country) {
        case "ru":
          return {
            ru: "https://www.amway.ru",
            en: "https://www.amway.ru/en",
            main: "https://www.amway.ru",
          };
        case "kz":
          return {
            ru: "https://www.kz.amway.com",
            kk: "https://www.kz.amway.com/kk",
            main: "https://www.kz.amway.com",
          };
        case "ua":
          return {
            ru: "https://www.amway.ua/ru",
            uk: "https://www.amway.ua",
            main: "https://www.amway.ua",
          };
      }
    },
    Currency() {
      switch (this.$route.params.country) {
        case "ru":
          return "₽";
        case "kz":
          return "₸";
        case "ua":
          return "₴";
      }
    },
    countryId() {
      switch (this.$route.params.country) {
        case "ru":
          return 1;
        case "kz":
          return 2;
        case "ua":
          return 3;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchUserTypes",
      "fetchCategories",
      "fetchBadges",
      "fetchAlerts",
      "fetchCreatePromo",
      "fetchUpdatePromo",
      "fetchPromo",
      "clearPromo",
      "addPromoImage",
      "searchProducts",
      "deleteBadges",
    ]),
    async validate() {
      this.$refs.card.validate();
      if (this.valid) {
        this.save(true);
      } else {
        this.fetchAlerts({
          type: "error",
          alerts: ["Проверьте правильность заполнения полей"],
        });
      }
    },
    fillFormData() {
      let translates = [];
      for (let lang of this.supportedLanguages) {
        translates.push({
          language: lang.id,
          name: this.name[lang.code],
          promo_condition_title: "promo_condition_title",
          short_description: this.short_cond[lang.code],
          promo_condition_text: this.promo_condition_text[lang.code],
          promo_condition_file_text: this.promo_condition_file_text[lang.code],
          link: this.link[lang.code],
          link_text: this.link_text[lang.code],
          brandname: this.brandname[lang.code],
        });
      }
      return {
        available_for: this.user_types,
        badges: this.badges[0] !== null ? this.badges : [],
        categories: this.category,
        end_date: this.dates[1],
        article: this.article,
        local_sku: this.local_sku,
        start_date: this.dates[0],
        price_abo: this.prices.abo.new || 0,
        price_old_abo: this.prices.abo.old || 0,
        price_old_public: this.prices.public.old || 0,
        price_old_rc: this.prices.rc.old || 0,
        price_old_vip: this.prices.vip.old || 0,
        price_public: this.prices.public.new || 0,
        price_rc: this.prices.rc.new || 0,
        price_vip: this.prices.vip.new || 0,
        image: this.getPromo.image,
        discount_abo: this.prices.abo.percent || 0,
        discount_public: this.prices.public.percent || 0,
        discount_rc: this.prices.rc.percent || 0,
        discount_vip: this.prices.vip.percent || 0,
        translates: translates,
      };
    },
    async save(state) {
      let fd = this.fillFormData();
      await this.fetchUpdatePromo({
        body: fd,
        id: this.getPromo.id,
        type: this.getPromo.content_type,
        save: state,
      });
    },
    getTranslate(lang) {
      return this.getPromo.translates.find((el) => el.language.id === lang);
    },
    getFile(lang_id) {
      if (
        this.getTranslate(lang_id) &&
        this.getTranslate(lang_id).promo_condition_file
      ) {
        return this.getTranslate(lang_id).promo_condition_file;
      }
      return "";
    },
    async uploadImage(e) {
      let file = e.target.files;
      if (!file && !file.length) return;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      await this.addPromoImage(formData);
    },
    clearPromoInfo() {
      this.dates = [];
      this.article = "";
      this.local_sku = "";

      this.category = [];
      this.badges = [];
      this.user_types = this.userTypes.map((el) => el.id);
      for (let type of this.getPromo.available_for) {
        this.prices[type.code] = {
          old: null,
          percent: null,
          new: null,
        };
      }
      if (this.supportedLanguages && this.supportedLanguages.length) {
        for (let i = 0; i < this.supportedLanguages.length; i++) {
          let lang = this.supportedLanguages[i];
          this.short_cond[lang.code] = "";
          this.name[lang.code] = "";
          this.brandname[lang.code] = "";
          this.promo_condition_text[lang.code] = "";
          this.promo_condition_file_text[lang.code] = "";
          this.link[lang.code] = "";
          this.link_text[lang.code] = "";
        }
      }
    },
    discountChange() {
      for (let type of this.userTypes) {
        if (this.user_types.includes(type.id)) {
          if (
            this.prices[type.code].percent &&
            parseInt(this.prices[type.code].percent)
          ) {
            this.badges = [];
            this.disabledBadges = true;
            return;
          } else {
            this.disabledBadges = false;
          }
        }
      }
    },
    updatePromoInfo() {
      this.dates = [];
      if (this.getPromo.start_date) {
        this.dates.push(this.getPromo.start_date);
      }
      if (this.getPromo.end_date) {
        this.dates.push(this.getPromo.end_date);
      }
      if (this.getPromo.article) {
        this.article = this.getPromo.article;
      }
      if (this.getPromo.local_sku) {
        this.local_sku = this.getPromo.local_sku;
      }
      if (
        this.getPromo &&
        this.getPromo.categories &&
        this.getPromo.categories.length
      ) {
        this.category = this.getPromo.categories.map((el) => el.id);
      }
      if (
        this.getPromo &&
        this.getPromo.badges &&
        this.getPromo.badges.length
      ) {
        this.badges = this.getPromo.badges.map((el) => el.id);
      }
      if (
        this.getPromo &&
        this.getPromo.available_for &&
        this.getPromo.available_for.length
      ) {
        this.user_types = this.getPromo.available_for.map((el) => el.id);
        for (let type of this.userTypes) {
          if (this.user_types.includes(type.id)) {
            this.prices[type.code] = {
              old: this.getPromo["price_old_" + type.code],
              percent: this.getPromo["discount_" + type.code],
              new: this.getPromo["price_" + type.code],
            };
          } else {
            this.prices[type.code] = {
              old: null,
              percent: null,
              new: null,
            };
          }
        }
      }
      if (this.supportedLanguages && this.supportedLanguages.length) {
        for (let i = 0; i < this.supportedLanguages.length; i++) {
          let lang = this.supportedLanguages[i];
          let translates = this.getTranslate(lang.id);
          this.short_cond[lang.code] = translates
            ? translates.short_description
            : "";
          this.name[lang.code] = translates ? translates.name : "";
          this.promo_condition_text[lang.code] = translates
            ? translates.promo_condition_text
            : "";
          this.promo_condition_file_text[lang.code] = translates
            ? translates.promo_condition_file_text
            : "";
          this.link[lang.code] = translates ? translates.link : "";
          this.link_text[lang.code] = translates ? translates.link_text : "";
          this.brandname[lang.code] = translates ? translates.brandname : "";
        }
      }
    },
    searchPromoInfo(product) {
      if (!product) return;
      this.clearPromoInfo();
      this.article = product.ru.alias;
      this.local_sku = product.ru.code;
      this.getPromo.image = this.setImgPath(product.ru.images);
      if (this.supportedLanguages && this.supportedLanguages.length) {
        for (let i = 0; i < this.supportedLanguages.length; i++) {
          let lang = this.supportedLanguages[i];
          this.name[lang.code] = product[lang.code].name;
          this.brandname[lang.code] = product[lang.code].brandname;
          this.link[lang.code] =
            this.catalogHost[lang.code] + product[lang.code].url;
        }
      }
    },
    setImgPath(arr) {
      if (!arr && typeof arr !== "Array") return null;
      const obj = arr.find((el) => el.format === "product");
      if (obj && Object.keys(obj).length) {
        return `${this.catalogHost.main}${obj.url}`;
      } else {
        return null;
      }
    },
    openBadgePopup() {
      this.addBadgeModal = true;
    },
    closeBadgePopup() {
      this.editedBadge = null;
      this.addBadgeModal = false;
    },
    editBadge(badge) {
      this.editedBadge = badge;
      this.addBadgeModal = true;
    },
    confirmDelete(item) {
      this.deleteConfirm = true;
      this.deletedItem = item;
    },
    removeBadge(state) {
      if (state) {
        this.deleteBadges(this.deletedItem.id);
        this.deleteConfirm = false;
      } else {
        this.deleteConfirm = false;
      }
    },
    closePicker(ev) {
      if (this.openCalendar && !ev.target.closest(".js-picker"))
        this.openCalendar = false;
    },
  },
  beforeDestroy() {
    this.clearPromo();
    document.removeEventListener("click", this.closePicker, false);
  },
  async mounted() {
    window.addEventListener("beforeunload", (e) => {
      this.clearPromo();
      e.preventDefault();
    });
    if (!this.userTypes.length) {
      await this.fetchUserTypes();
    }
    if (!this.Categories.length) {
      await this.fetchCategories();
    }
    if (!this.Badges.length) {
      await this.fetchBadges();
    }
    if (!Object.keys(this.getPromo).length) {
      await this.fetchPromo({ id: this.$route.params.id, type: "product" });
      if (!Object.keys(this.getPromo).length) {
        this.$router.push({ name: "PromoList" });
      }
      this.updatePromoInfo();
    }
    document.addEventListener("click", this.closePicker);
  },
  watch: {
    getPromo() {
      this.updatePromoInfo();
    },
    badges() {
      if (this.badges.length && this.badges[0]) {
        for (let type of this.userTypes) {
          this.prices[type.code].percent = 0;
          this.prices[type.code].disabled = true;
        }
      } else {
        for (let type of this.userTypes) {
          this.prices[type.code].disabled = false;
        }
      }
    },
    user_types() {
      this.discountChange();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>