<template>
    <div class="file-component my-5">
        <a download :href="file" target="_blank" class="file-component__title" v-if="file">
            <v-icon
            color="#0A467E"
            class="mr-2">
                mdi-tray-arrow-down
            </v-icon>
            <div class="file-component__title-text">
               {{fileName}}
            </div>
        </a>
        <div class="file-component__title file-component__title_add" @click="openPopup = true" v-else>
            <v-icon
            color="#0A467E"
            class="mr-2">
                mdi-plus
            </v-icon>
            <div class="file-component__title-text">
                Добавить файл с условиями 
            </div>
        </div>
        <div class="file-component__buttons" v-if="!!file"
        :class="{'mt-4': !$vuetify.breakpoint.smAndUp}">
            <v-btn
            elevation="0"
            color="#0A467E"
            text
            :ripple="false"
            height="44"
            :class="{'ml-4': $vuetify.breakpoint.smAndUp, 'pa-0 mr-4': !$vuetify.breakpoint.smAndUp}"
            @click="openPopup = true">
                Заменить
            </v-btn>
             <v-btn
            elevation="0"
            color="#EB174B"
            text
            :ripple="false"
            height="44"
            @click="deleteConfirm = true">
                Удалить
            </v-btn>
        </div>
        <div class="file-component__popup pa-6" v-if="openPopup">
            <div class="file-component__popup-close" @click="openPopup = false">
                <v-icon
                color="#0A467E">
                    mdi-close
                </v-icon>
            </div>
            <div class="file-component__popup-caption">
                Добавьте файл с условиями акции
            </div>
            <v-form
            v-model="valid"
            ref="fileLink">
                <v-row
                no-gutters
                align-lg="end">
                    <v-col
                    md="9"
                    cols="12">
                        <v-text-field
                        v-model="link"
                        :rules="[Rules.Link]"
                        outlined
                        messages="Ссылка на файл с условиями акции"
                        background-color="#fff"
                        color="#858585"
                        class="v-input--counted"
                        placeholder="Добавьте ссылку на файл"

                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                    md="3"
                    cols="12">
                        <v-btn
                            elevation="0"
                            color="#0A467E"
                            dark
                            tile
                            :disabled="!valid"
                            @change="$refs.fileLink.validate()"
                            @input="$refs.fileLink.validate()"
                            :ripple="false"
                            block
                            @click="addFromLink"
                            :height="$vuetify.breakpoint.lgAndUp ? 56: 44"
                            :class="{'mb-2 ml-n1': $vuetify.breakpoint.lgAndUp, 'mb-6': !$vuetify.breakpoint.lgAndUp}"
                        >
                            Добавить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
           
            <div class="file-component__popup-message">
                Или вы можете загрузить файл со своего устройства
            </div>
            <div class="file-component__button-wrap mt-3">
                <input @change="uploadFile" ref="fileObj" class="file-component__input" type="file" name="file">
                <v-btn
                    elevation="0"
                    color="#008FC5"
                    dark
                    tile
                    :block="!$vuetify.breakpoint.lgAndUp"
                    :ripple="false"
                    height="44"
                    class="px-10"
                    :loading="loading"
                    @click="$refs.fileObj.click()"
                >
                    Выбрать файл
                </v-btn>
            </div>
           
        </div>
        <Dialog :title="'файл ' + fileName" v-if="deleteConfirm" @dialogState="deleteFile"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Dialog from '@/components/help/Dialog.vue';
export default {
    name: "fileComponent",
    props: {
        file: String,
        lang: Number
    },
    components: {
        Dialog
    },
    data: () => ({
        deleteConfirm: false,
        openPopup: false,
        link: "",
        valid: false,
        loading: false
    }),
    computed: {
        ...mapGetters(['getPromo', 'Rules']),
        fileName() {
            let filepath =  this.file.split("/");
            return filepath[filepath.length - 1]
        }
    },
    methods: {
        ...mapActions(['addPromoFile', 'fetchUpdatePromo']),
        getTranslate(lang) {
            return this.getPromo.translates.find((el) => (el.language.id === lang))
        },
        async uploadFile(e) {
            this.$emit('save')
            this.loading = true
            let file = e.target.files
            if(!file && !file.length) return
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            await this.addPromoFile({body: formData, lang: this.lang})
            if(this.getPromo.translates && this.getPromo.translates.length) {
                this.fetchUpdatePromo({
                   body: {
                       translates: [
                           {
                            language: this.lang,
                            promo_condition_file: this.getTranslate(this.lang).promo_condition_file
                           }
                       ] 
                   },
                   id: this.getPromo.id,
                   type: this.getPromo.content_type
                })  
            }
            this.openPopup = false
            this.loading = false
        },
        addFromLink() {
            this.$emit('save')
            if(this.getPromo.translates && this.getPromo.translates.length) {
                this.fetchUpdatePromo({
                    body: {
                        translates: [
                            {
                            language: this.lang,
                            promo_condition_file: this.link
                            }
                        ] 
                    },
                    id: this.getPromo.id,
                    type: this.getPromo.content_type
                })  
            }
            this.openPopup = false
        },
         async deleteFile(state) {
            this.$emit('save')
            if(state) {
                if(this.getPromo.translates && this.getPromo.translates.length) {
                    this.fetchUpdatePromo({
                    body: {
                        translates: [
                            {
                                language: this.lang,
                                promo_condition_file: null
                            }
                        ] 
                    },
                    id: this.getPromo.id,
                    type: this.getPromo.content_type
                    })  
                    this.deleteConfirm = false
                }
            }
            else {
                this.deleteConfirm = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .file-component {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: stretch;
        }

        &__button-wrap {
            position: relative;
        }

        &__input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            font-size: 0;
            cursor: pointer; 

            &::-webkit-file-upload-button { /* chromes and blink button */
                cursor: pointer; 
            }
        }

        &__buttons {
            white-space: nowrap;
        }

        &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #0A467E;
            flex-shrink: 1;
            text-decoration: none;
            max-width: 50%;

            &_add {
                max-width: none;
            }

            &-text {
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-family: "Source Sans Pro", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-decoration-line: underline;
            }
        }

        &__popup {
            position: absolute;
            width: 100%;
            top: calc(100% + 15px);
            left: 0;
            z-index: 10;
            background-color: #F9F9F9;

            &::before {
                position: absolute;
                top: -8px;
                left: 10%;
                display: block;
                content: "";
                height: 8px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid #F9F9F9;
            }

            &-close {
                position: absolute;
                top: 30px;
                right: 30px;
                @media screen and (max-width: 600px) {
                    top: 20px;
                    right: 20px;
                }
            }

            &-caption {
                margin-bottom: 15px;
                font-family: "Roboto Condensed", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                color: #56565A;
                @media screen and (max-width: 600px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            &-message {
                margin-top: 15px;
                font-family: "Source Sans Pro", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #56565A;
            }
        }
    }
</style>