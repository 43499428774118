<template>
    <v-dialog
      v-model="dialog"
      max-width="fit-content"
    >
      <v-card>
        <v-card-title class="text-h5">
          Вы действительно хотите удалить {{title}}?
        </v-card-title>
        <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    elevation="0"
                    color="#0A467E"
                    outlined
                    tile
                    height="44"
                    class="px-10"
                    @click="$emit('dialogState', false)"
                >
                    Нет
                </v-btn>
                    
                <v-btn
                    elevation="0"
                    color="#0A467E"
                    dark
                    tile
                    height="44"
                    class="px-10"
                    @click="$emit('dialogState', true)"
                >
                    Да
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "Dialog",
    data: () => ({
        dialog: true
    }),
    props: {
        title: String
    }
}
</script>

<style>

</style>