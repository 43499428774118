<template>
  <section class="search-product">
    <v-card class="pa-4 pa-md-5" color="#F7F7F7" elevation="0" tile>
      <h3 class="promo-title-3 mb-4">Добавить продукт из каталога</h3>

      <v-form
        @submit.prevent="sendSearch"
        class="d-flex align-stretch align-self-center flex-column flex-md-row"
      >
        <v-text-field
          v-model="querySearch"
          placeholder="Поиск товара"
          background-color="#fff"
          hide-details="false"
          height="44px"
          outlined
          dense
        >
        </v-text-field>

        <v-btn
          class="search__btn px-8 mt-2 mt-md-0"
          elevation="0"
          color="#0A467E"
          height="44px"
          dark
          :loading="loading"
          tile
          type="submit"
        >
          Найти
        </v-btn>
      </v-form>

      <transition name="height">
        <section
          v-if="
            getProducts &&
            Object.keys(getProducts).length &&
            getProducts.ru !== 'empty'
          "
          class="search__result mt-5"
        >
          <v-card
            v-for="product in getProducts.ru"
            :key="product.id"
            class="pa-3 ma-0 pa-md-4 ma-md-2"
            color="#FFF"
            elevation="0"
            tile
          >
            <v-row class="flex-nowrap">
              <div class="mx-2 my-4 mx-md-5 my-md-5 search__img">
                <img
                  :src="setImgPath(product.images)"
                  alt=""
                  class="search__img"
                />
              </div>

              <div class="px-2 py-4 px-md-5 py-md-5">
                <h4 class="title-wrap title-4">{{ product.name }}</h4>
                <h4 class="title-wrap title-4 mt-1 mb-1">
                  {{ product.brandName }}
                </h4>
                <h5 class="title-wrap title-5">Артикул {{ product.alias }}</h5>
                <h5 class="title-wrap title-5">Local SKU {{ product.code }}</h5>

                <div
                  class="search__btn_add mt-2"
                  @click="returnProductInfo(product.alias)"
                  v-if="getCarouselItems.findIndex((el) => el.article === product.alias) < 0"
                >
                  <span> Добавить продукт </span>
                </div>
                <div v-else class="search__btn_isset mt-2">
                  <v-icon :color="'#27A248'"
                      v-bind="attrs"
                       >
                        mdi-check-circle-outline
                    </v-icon>
                  <span>Добавлен в список</span>
                </div>
              </div>
            </v-row>
          </v-card>
        </section>

        <section v-if="getProducts.ru === 'empty'">
          <p class="pt-10 mb-0 d-flex justify-center">Товаров не найденно</p>
        </section>
      </transition>
    </v-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SearchProduct",
  data: () => ({
    querySearch: "",
    loading: false,
  }),
  computed: {
    ...mapGetters(["getProducts", "getPromo", "getCarouselItems"]),
    supportedLanguages() {
      if (
        this.getPromo.country &&
        this.getPromo.country.languages &&
        this.getPromo.country.languages.length
      ) {
        return this.getPromo.country.languages.map((el) => el.code);
      }
      switch (this.$route.params.country) {
        case "ru":
          return ["ru", "en"];
        case "kz":
          return ["ru", "kz"];
        case "ua":
          return ["ru", "ua"];
        default:
          return ["ru", "en"];
      }
    },
    issetInList() {
      return this.getCarouselItems.filter((el) => this.getProducts.ru.find((p) => el.article === p.alias))
    },
    catalogHost() {
      switch (this.$route.params.country) {
        case "ru":
          return "https://www.amway.ru";
        case "kz":
          return "https://www.kz.amway.com";
        case "ua":
          return "https://www.amway.ua";
      }
    },
  },
  methods: {
    ...mapActions(["searchProductsObj", "clearSearch"]),
    async sendSearch() {
      if (!!this.querySearch) {
        this.loading = true;
        await this.searchProductsObj({
          queryString: this.querySearch,
          country: this.$route.params.country,
          langs: this.supportedLanguages,
        });
      } else {
        await this.clearSearch();
      }
      this.loading = false;
    },
    returnProductInfo(alias) {
      let product = {};
      for (let lang in this.getProducts) {
        let items = this.getProducts[lang];
        if (items !== "empty") {
          product[lang] = items.find((el) => el.alias === alias);
        } else {
          product[lang] = {
            name: "",
            link: "",
          };
        }
      }
      this.$emit("add", product);
      if (this.$route.name !== "PromoCarousel") {
        this.querySearch = "";
      }
    },
    setImgPath(arr) {
      if (!arr && typeof arr !== "Array")
        return "~@/assets/img/choch_desktop.jpg";
      const obj = arr.find((el) => el.format === "thumbnail");
      if (obj && Object.keys(obj).length) {
        return `${this.catalogHost}${obj.url}`;
      } else {
        return "~@/assets/img/choch_desktop.jpg";
      }
    },
  },
  watch: {
    async querySearch() {
      if (this.querySearch.length === 0) {
        await this.clearSearch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-title-3 {
  font-family: "Source Sans Pro";
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #56565a;
}

.search__img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 3px;
  overflow: hidden;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    width: 75px;
    height: 75px;
    min-width: 75px;
  }
}

.search__btn {
  transition: opacity 0.3s ease;
  &_disabled {
    opacity: 0.75;
    cursor: default;
    pointer-events: none;
  }
  &_add {
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 19px;
    color: #0a467e;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  &_isset {
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 19px;
    color: #27a248;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;

    & span {
      margin-left: 4px;
    }
  }
}

.search__result {
  max-height: 350px;
  overflow-y: auto;
}

.title-wrap {
  @media screen and (max-width: 600px) {
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.title-4 {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #56565a;
}

.title-5 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56565a;
}
</style>
