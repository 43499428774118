<template>
  <section class="promo-header d-flex justify-space-between align-center flex-wrap mt-7 mt-md-0">
    <h2 class="promo-title">
      {{ caption }}
    </h2>

    <div v-if="$route.name === 'PromoList'"
      class="promo-header__buttons d-flex flex-wrap my-md-2 my-0 mt-5 mt-md-0">
        <v-btn elevation="0"
          color="#008FC5"
          dark tile
          height="44"
          class="btn_m-100 px-8 my-1 my-md-0"
          @click="createPromo('promotion')"
        >
          <v-icon color="#fff" class="mr-1">
            mdi-plus
          </v-icon>
          <span>Добавить предложение</span>
        </v-btn>

        <v-btn elevation="0"
          color="#008FC5"
          dark tile
          height="44"
          class="btn_m-100 ml-0 ml-md-5 px-8 my-1 my-md-0"
          @click="createPromo('product')"
        >
          <v-icon color="#fff" class="mr-1">
            mdi-plus
          </v-icon>
          <span>Добавить скидку</span>
        </v-btn>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HeaderPromo',
  props: {
    caption: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['getPromo', 'userTypes']),
    countryId() {
      switch(this.$route.params.country) {
        case 'ru':
          return 1
        case 'kz':
          return 2
        case 'ua':
          return 3
      }
    }
  },
  methods: {
    ...mapActions(['fetchCreatePromo','fetchUserTypes']),
    async createPromo(type) {
      if(!this.$route.params.country || !type) {
        return 
      }
      await this.fetchCreatePromo({type: type, country: this.countryId, available_for: this.userTypes.map((el) => el.id)})
      if(this.getPromo.content_type === "promotion") {
        this.$router.push({name: "PromoDetailPromo", params: {id: this.getPromo.id}})
      } else if(this.getPromo.content_type === "product") {
        this.$router.push({name: "PromoDetailProduct", params: {id: this.getPromo.id}})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.promo-title {
  font-size: 36px;
  line-height: 38px;
  font-weight: 700;
  color: #56565A;

  @media screen and (max-width: 560px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.btn_m-100 {
  @media screen and ( max-width: 600px ) {
    width: 100%;
  }
}
</style>
